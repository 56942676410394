import { Action, Module, Mutation, VuexModule } from "vuex-module-decorators";
import store from "@/store";

interface defaultView {
  defaultView: Record<string, unknown>;
}

interface View {
  columns: Record<string, unknown>;
  row_count: number;
}

interface Data {
  rows: Record<string, unknown>;
  meta: {
    currentPage: number;
    from: number | null;
    lastPage: number;
    links: [];
    path: string;
    perPage: number;
    to: number | null;
    total: number;
  };
}

interface CurrentPage {
  currentPage: number;
}

/*
interface DefaultView {
  defaultView: number;
}*/

interface Endpoints {
  endpoints: {
    column: string;
    row: string;
  };
}

interface replaceEndpoint {
  query: string;
  value: Array<string>;
}

interface DataResourceArray {
  [resource: string]: {
    [index: number]: Record<string, unknown>;
  };
}

interface Filtered {
  filtered: boolean;
}

interface Filter {
  filter: Record<string, unknown>;
}

interface Resized {
  filtered: boolean;
}

interface SelectedFilter {
  fulltext: string | null;
  filter: Array<string> | null;
  customFilter: Record<string, unknown>;
}

@Module({ namespaced: true })
class DataTableStore extends VuexModule {
  public tables: DataResourceArray = {};
  private _tableSource: string = "";
  private _tableKey: number = 0;
  public refreshFlag = false;

  get activeColumns() {
    return this.tables[this._tableSource][this._tableKey].columns;
  }

  @Mutation
  toggleRefreshFlag() {
    this.refreshFlag = !this.refreshFlag;
  }

  @Mutation
  setTableKey(p) {
    this._tableKey = p;
  }

  @Action
  refreshTable() {
    this.context.commit("toggleRefreshFlag");
  }

  @Mutation
  setTableSource(source: string) {
    this._tableSource = source;
  }

  @Mutation
  RESET_FILTER() {
    Object.keys(this.tables).forEach(resource => {
      Object.values(this.tables[resource]).forEach(table => {
        table.selectedFilter = "";
        table.filtered = false;
      });
    });
  }

  @Action
  resetFilter() {
    this.context.commit("RESET_FILTER");
  }

  @Action({ rawError: true })
  resourceName(_tableKey) {
    const _resource = store.state.routeMeta.routeMeta.resources;

    if (_resource && Array.isArray(_resource)) {
      if (_resource.length > 1) {
        this.context.commit("setTableSource", _resource.join() || "");
      } else {
        this.context.commit("setTableSource", _resource[0] || "");
      }
    }
  }

  @Action({ rawError: true })
  setDataTableEndpoint(payload: { _tableKey: number; _data: Endpoints }): void {
    this.context.commit("setTableKey", payload._tableKey);
    this.context.dispatch("resourceName", payload._tableKey).then(() => {
      const _resource = this._tableSource;
      this.tables[_resource] = this.tables[_resource] || {}; // Initialize if undefined

      if (this.tables[_resource][payload._tableKey] === undefined) {
        this.tables[_resource][payload._tableKey] = {
          endpoints: {
            column: payload._data.endpoints.column,
            row: payload._data.endpoints.row,
          },
        };
      } else {
        this.tables[_resource][payload._tableKey] = {
          defaultView: this.tables[_resource][payload._tableKey].defaultView,
          endpoints: {
            column: payload._data.endpoints.column,
            row: payload._data.endpoints.row,
          },
          currentPage: this.tables[_resource][payload._tableKey].currentPage,
          column: this.tables[_resource][payload._tableKey].column,
          row_count: this.tables[_resource][payload._tableKey].row_count,
          row: this.tables[_resource][payload._tableKey].row,
          meta: this.tables[_resource][payload._tableKey].meta,
          filtered: this.tables[_resource][payload._tableKey].filtered,
          filter: this.tables[_resource][payload._tableKey].filter,
          resized: this.tables[_resource][payload._tableKey].resized,
          selectedFilter: this.tables[_resource][payload._tableKey].selectedFilter,
        };
      }
    });
  }

  @Action({ rawError: true })
  setNewDataTableEndpoint(payload: { _tableSource: string; _tableKey: number; _data: replaceEndpoint }): void {
    this.context.commit("setTableKey", payload._tableKey);
    this.context.dispatch("resourceName", payload._tableKey).then(() => {
      const _resource = this._tableSource;
      const _env: any = process.env.VUE_APP_BASE_API;

      const existingTable = this.tables[_resource][payload._tableKey] || {}; // Check if the table exists
      const _endpoints: any = existingTable.endpoints || {}; // Check if the endpoints property exists

      const _url: any = new URL(_env + _endpoints.row);

      let _pathname = _url.pathname;

      const params = new URLSearchParams(_url.search);

      //remove "/api/v1/" from URL
      if (_pathname.substring(0, 4) == "/api") {
        const removeApiV1 = _env.replace(_url.origin, ""); //return /api/v1/
        _pathname = _pathname.replace(removeApiV1, "");
      }

      const keysForDel: string[] = [];

      //IT WAS GET
      params.forEach((v, k) => {
        if (k.search(payload._data.query) > -1 && v !== "") keysForDel.push(k);
      });

      keysForDel.forEach((k) => {
        params.delete(k);
      });

      if (payload._data.query !== "filter") {
        for (const key of payload._data.value) {
          if (Object.values(key).toString() !== "")
            params.append(Object.keys(key).toString(), Object.values(key).toString());
        }
      }

      //console.log("keysForDel", params);

      if (_pathname.charAt(0) == "/") _pathname = _pathname.substr(1); //remove first slash

      this.tables[_resource][payload._tableKey].endpoints = {
        column: _endpoints.column,
        row: _pathname + "?" + params.toString(),
      };
    });
  }

  @Action
  setCurrentPage(payload: { _tableSource: string; _tableKey: number; _currentPage: CurrentPage }): void {
    this.context.dispatch("resourceName", payload._tableKey).then(() => {
      const _resource = this._tableSource;
      this.tables[_resource][payload._tableKey]["currentPage"] = payload._currentPage;
    });
  }

  @Action
  setDataTableDefaultView(payload: { _tableSource: string; _tableKey: number; _data: defaultView }): void {
    this.context.commit("setTableKey", payload._tableKey);
    this.context.dispatch("resourceName", payload._tableKey).then(() => {
      const _resource = this._tableSource;
      this.tables[_resource][payload._tableKey] = {
        defaultView: payload._data,
        endpoints: this.tables[_resource][payload._tableKey].endpoints,
        currentPage: this.tables[_resource][payload._tableKey].currentPage,
        columns: this.tables[_resource][payload._tableKey].columns,
        row_count: this.tables[_resource][payload._tableKey].row_count,
        rows: this.tables[_resource][payload._tableKey].rows,
        meta: this.tables[_resource][payload._tableKey].meta,
        filtered: this.tables[_resource][payload._tableKey].filtered,
        filter: this.tables[_resource][payload._tableKey].filter,
        resized: false,
        selectedFilter: this.tables[_resource][payload._tableKey].selectedFilter,
      };
    });
  }

  @Action
  setDataTableColumns(payload: { _tableSource: string; _tableKey: number; _data: View }): void {
    this.context.dispatch("resourceName", payload._tableKey).then(() => {
      const _resource = this._tableSource;
      this.tables[_resource] = this.tables[_resource] || {}; // Initialize if undefined

      // Check if this.tables[resource][payload._tableKey] is defined
      if (!this.tables[_resource][payload._tableKey]) {
        this.tables[_resource][payload._tableKey] = {};
      }

      this.tables[_resource][payload._tableKey] = {
        defaultView: this.tables[_resource][payload._tableKey].defaultView,
        endpoints: this.tables[_resource][payload._tableKey].endpoints,
        currentPage: 1,
        columns: payload._data.columns,
        row_count: payload._data.row_count,
        meta: this.tables[_resource][payload._tableKey].meta,
        filtered: this.tables[_resource][payload._tableKey].filtered,
        filter: this.tables[_resource][payload._tableKey].filter,
        selectedFilter: this.tables[_resource][payload._tableKey].selectedFilter,
        resized: this.tables[_resource][payload._tableKey].resized,
      };
    });
  }

  @Action
  setDataTableRows(payload: { _tableSource: string; _tableKey: number; _data: Record<string, unknown> }): void {
    this.context.dispatch("resourceName", payload._tableKey).then(() => {
      //const _resource = this._tableSource;
      const _resource = payload._tableSource;
      const columns = this.tables[_resource][payload._tableKey].columns;
      const row_count = this.tables[_resource][payload._tableKey].row_count;
      this.tables[_resource][payload._tableKey] = {
        defaultView: this.tables[_resource][payload._tableKey].defaultView,
        endpoints: this.tables[_resource][payload._tableKey].endpoints,
        currentPage: this.tables[_resource][payload._tableKey].currentPage,
        columns: columns,
        row_count: row_count,
        rows: payload._data.rows,
        meta: payload._data.meta,
        filtered: this.tables[_resource][payload._tableKey].filtered,
        filter: this.tables[_resource][payload._tableKey].filter,
        resized: this.tables[_resource][payload._tableKey].resized,
        selectedFilter: this.tables[_resource][payload._tableKey].selectedFilter,
      };
    });
  }

  @Action
  setFiltered(payload: { _tableSource: string; _tableKey: number; _filtered: Filtered }): void {
    this.context.dispatch("resourceName", payload._tableKey).then(() => {
      //const _resource = this._tableSource;
      const _resource = payload._tableSource;
      this.tables[_resource][payload._tableKey]["filtered"] = payload._filtered;
    });
  }

  @Action
  setFilterObject(payload: { _tableSource: string; _tableKey: number; _filter: Filter }): void {
    this.context.dispatch("resourceName", payload._tableKey).then(() => {
      //const _resource = this._tableSource;
      const _resource = payload._tableSource;
      this.tables[_resource][payload._tableKey]["filter"] = payload._filter;
    });
  }

  @Action
  setResizedColumn(payload: { _tableSource: string; _tableKey: number; _resized: Resized }): void {
    this.context.dispatch("resourceName", payload._tableKey).then(() => {
      const _resource = this._tableSource;
      this.tables[_resource][payload._tableKey]["resized"] = payload._resized;
    });
  }

  @Action
  setSelectedFilter(payload: { _tableSource: string; _tableKey: number; _selectedFilter: SelectedFilter }): void {
    this.context.dispatch("resourceName", payload._tableKey).then(() => {
      const _resource = this._tableSource;
      this.tables[_resource][payload._tableKey]["selectedFilter"] = payload._selectedFilter;
    });
  }
}

export default DataTableStore;
